/* Ensure to import a font library like Google Fonts in your HTML or CSS */

.product-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  margin: 5px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  width: 300px;
  font-family: "Poppins", sans-serif;

}

.product-card:hover {
  transform: scale(1.03);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.product-header {
  margin-bottom: 5px;
}

.product-name {
  font-size: 1.2em;
  font-weight: 600;
  margin: 0;
  color: #333;
  font-family: "Poppins", sans-serif;
}

.product-details {
  font-size: 0.9em;
  color: #555;
}

.details-line {
  margin: 0;
  line-height: 1;
}

.pkg-price-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.pkg {
  font-size: 0.9em;
  color: #777;
}

.price {
  margin-right: 10px;
  font-size: 1.2em;
  font-weight: 700;
  color: #d9534f;
}

.division {
  font-style: italic;
  color: #888;
}

.notes {
  font-style: italic;
  color: #666;
  font-size: 0.85em;
}

/* Mobile-friendly adjustments */
@media (max-width: 768px) {
  .product-card {
    width: 100%; /* Full width on mobile */
    margin: 10px 0; /* More spacing between cards */
  }

  .product-name {
    font-size: 1em; /* Slightly smaller font for mobile */
  }

  .pkg-price-detail {
    flex-direction: column; /* Stack package and price */
    align-items: flex-start; /* Align to the start */
  }

  .price {
    margin-right: 0;
    margin-top: 5px; /* Add spacing between package and price */
  }
}
/* Add this for logo styling */
.product-logo {
  width: 80px; /* Adjust the size as needed */
  margin-bottom: 10px; /* Space between the logo and product name */
}

/* Other styles remain unchanged */
