.add-product-container {
  width: 90%;
  /* max-width: 600px; */
  margin: 0 auto;
  padding: 20px;
}

.input-group {
  margin-bottom: 15px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
}

.input-group input,
.input-group select,
.input-group textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.input-group-inline {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.inline-group {
  flex: 1;
}

.inline-group label {
  display: block;
  margin-bottom: 5px;
}

.inline-group input,
.inline-group select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}


.submit-button {
  margin-right: 10px;
  padding: 12px 24px; /* Increased padding for a more prominent button */
  background-color: #9b59b6;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px; /* Larger font size for better readability */
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #9b59b6; /* Darker shade on hover */
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-message,
.success-message {
  color: red;
  margin-top: 10px;
}
