body, html {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    background: linear-gradient(135deg, #71b7e6, #9b59b6);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .App {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
  }
  
  .login-container {
    background: white;
    padding: 40px 60px;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%;
    max-width: 400px;
  }
  
  .login-title {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .input-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .input-group label {
    margin-bottom: 5px;
    font-size: 14px;
    color: #555;
  }
  
  .input-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .login-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background: #9b59b6;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .login-button:hover {
    background: #8e44ad;
  }
  
  @media (max-width: 600px) {
    .login-container {
      padding: 20px 30px;
    }
    
    .login-title {
      font-size: 20px;
    }
    
    .login-button {
      font-size: 14px;
    }
  }
  /* Login.css */
/* Login.css */

.login-loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #9b59b6; /* Blue */
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation: spin 2s linear infinite;
  margin: auto; /* Center the loader horizontally */
}

.login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px; /* Ensure the button has a fixed height */
  width: 100%; /* Ensure the button spans the full width of its container */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Other existing styles */

/* Existing CSS */
/* Add this for logo styling */
.login-logo {
  width: 200px; /* Adjust the width of the logo */
}

/* Other styles remain unchanged */
