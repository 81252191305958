body, html {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.category-master {
  background: white;
  padding: 40px 60px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 80%;
  max-height: 80vh;
  margin: 20px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .category-master {
    width: 95%;
    padding: 20px 30px;
  }

  .add-category input {
    width: 60%;
  }
}

@media (max-width: 480px) {
  .category-master {
    width: 100%;
    padding: 15px 20px;
  }

  .add-category {
    flex-direction: column;
  }

  .add-category input, .add-category button {
    width: 100%;
    margin-bottom: 10px;
    border-radius: 5px;
  }

  .search-bar-cat input {
    width: 100%;
  }
}

.category-master h2 {
  margin-bottom: 20px;
  font-size: 28px;
  color: #333;
}

.add-category {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.add-category input {
  padding: 10px;
  border-radius: 5px 0 0 5px;
  border: 1px solid #ccc;
  width: 300px;
}

.add-category button {
  padding: 10px 20px;
  border-radius: 0 5px 5px 0;
  border: none;
  background: #9b59b6;
  color: white;
  cursor: pointer;
  transition: background 0.3s ease;
}

.add-category button:hover {
  background: #8e44ad;
}

.search-bar-cat {
  margin: -10px;
  text-align: center;
}

.search-bar-cat input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
  max-width: 300px;
}

.table-container {
  max-height: 400px; /* Set a fixed height for the scrollable area */
  overflow-y: auto; /* Enable vertical scrolling */
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

thead {
  position: sticky; /* Keeps the header sticky */
  top: 0;
  background: #f4f4f4;
  z-index: 1; /* Ensures the header stays on top of the scrollable body */
}

th, td {
  padding: 12px 15px;
  border-bottom: 1px solid #ddd;
  text-align: center;
}

th {
  background: #f4f4f4;
}

.edit-button, .delete-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: color 0.3s ease;
}

.edit-button {
  color: #3498db;
}

.edit-button:hover {
  color: #2980b9;
}

.delete-button {
  color: #e74c3c;
}

.delete-button:hover {
  color: #c0392b;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #8e44ad;
  width: 45px;
  height: 45px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error {
  color: red;
  margin-top: 10px;
}
