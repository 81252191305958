/* ProductList.css */

.product-list-container {
  /* max-width: 1000px;  */
  min-width: 90vw;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 8px; /* Slightly larger border radius for a smoother look */
}

.heading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.search-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 95%;
  font-size: 16px;
}
.search-text-box{
  width: 50%;
  margin-bottom: -2px;
  padding: 2px;
}
.product-table-container {
  max-height: 400px; /* Adjust the height as needed */
  overflow-y: auto; /* Enables vertical scrolling */
  margin-top: 20px;
  border: 1px solid #ddd;
}

.product-table {
  width: 100%;
  border-collapse: collapse;
}

.product-table th, .product-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: center;
}

.product-table th {
  background-color: #9b59b6;
  color: white;
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 1;
}

.product-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.product-table tbody tr:hover {
  background-color: #e0e0e0;
}

.add-product-button {
  padding: 12px 24px; /* Increased padding for a more prominent button */
  background-color: #9b59b6;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px; /* Larger font size for better readability */
  transition: background-color 0.3s ease;
}

.add-product-button:hover {
  background-color: #8e44ad; /* Darker shade on hover */
}

.cancel-button {
  padding: 10px 20px;
  background-color: #f44336; /* Red background for cancel */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.cancel-button:hover {
  background-color: #d32f2f; /* Darker red on hover */
}

.list-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px; /* Adjust height to ensure proper centering */
}

.list-loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #9b59b6;
  border-radius: 50%;
  width: 40px; /* Larger loader */
  height: 40px;
  animation: spin 1.5s linear infinite; /* Faster spin animation */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media screen and (max-width: 768px) {
  .product-table th, .product-table td {
    padding: 6px; /* Adjust padding for smaller screens */
  }

  .add-product-button, .cancel-button {
    padding: 8px 16px; /* Adjust button size for smaller screens */
    font-size: 14px;
  }

  .loader {
    width: 30px; /* Adjust loader size for smaller screens */
    height: 30px;
  }

  .search-bar {
    width: 100%; /* Make search bar full width on smaller screens */
    margin-top: 10px;
  }
}

.product-table td.active {
  color: green; /* Green text for Active status */
  font-weight: bold; /* Optional: Make text bold */
}

.product-table td.inactive {
  color: red; /* Red text for Inactive status */
  font-weight: bold; /* Optional: Make text bold */
}
.status {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  color: #fff;
}

.status.active {
  background-color: green;
}

.status.inactive {
  background-color: red;
}
