.home-container {
  padding: 20px;
  box-sizing: border-box;
  padding-top: 40px;
  
}

.division-filter {
  background: white;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

.filter-tab {
  background-color: #9b59b6;
  color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 5px 0;
  width: 100%;
  text-align: center;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.division-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  min-width: 72%;
}

.division-header h2 {
  color: white;
  margin-left: 10px; /* Ensure there's spacing between the back button and the heading */
}

.no-products {
  font-size: 1.2em;
  color: #555;
  text-align: center;
  margin-top: 20px;
  width: 100%;
}


.back-button {
  background-color: #9b59b6;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s, color 0.3s;
}

.back-button:hover {
  background-color: #8e44ad;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-height: 80vh;
  overflow-y: auto;
}

.loading {
  font-size: 1.5em;
  color: #555;
  text-align: center;
  margin-top: 20px;
}
