.change-password-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .change-password-container form {
    display: flex;
    flex-direction: column;
  }
  
  .change-password-container label {
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .change-password-container input[type="text"],
  .change-password-container input[type="password"] {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .change-password-container button {
    padding: 10px 15px;
    background-color: #9b59b6;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .change-password-container button:hover {
    background-color: #a988b6;
  }
  
  .change-password-container .error-message {
    color: red;
    margin-top: -10px;
    margin-bottom: 10px;
    font-size: 0.9em;
  }
  
  .change-password-container input[disabled] {
    background-color: #e9ecef;
    cursor: not-allowed;
  }
  .password-container {
    position: relative;
  }
  .password-container input{
    width: 92%;
  }
  
  .password-toggle-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-90%);
    cursor: pointer;
    color: #007bff; /* Adjust color as needed */
    font-size: 20px;
  }